<template>
  <section class="pb-8" id="contact">
    <v-container fluid class="mt-7">
      <v-row align="center" justify="center" class="mt-8">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="12" align="center">
              <h1 class="font-weight-light display-1">Contact Us</h1>
              <h3 class="font-weight-light mt-3">
                We would love to hear your feedback.
              </h3>
              <h3 class="font-weight-light mt-3">
                Contact us to learn more about our research group and methods.
              </h3>
              <h3 class="font-weight-light mt-3">
                <strong>Email:</strong> barry.lehane@uwa.edu.au
              </h3>
              <h3 class="font-weight-light mt-3">
                If you experience technical difficulties when using this application, please contact Hoo Solutions.
              </h3>
              <h3 class="font-weight-light mt-3">
                <strong>Email:</strong> caitlin@hoosolutions.com.au
              </h3>

            </v-col>
            <v-col cols="12" sm="7">
              <!--<v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    required
                ></v-text-field>

                <v-textarea
                    v-model="textArea"
                    :rules="textAreaRules"
                    label="Message"
                    required
                />

                <v-btn
                    :disabled="!valid"
                    color="primary"
                    :dark="valid"
                    rounded
                    block
                    class="mt-3"
                    @click="submit"
                >
                   Send Message
                </v-btn>
              </v-form>-->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
        v-model="snackbar.enabled"
        timeout="3000"
        right
        top
        :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar.enabled = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
  margin-top: -30px
}
</style>

<script>
// import {db} from '@/main'

export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required"
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "A valid email is required",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "A message is required",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  methods: {
    submit() {
      /*db.collection("contactData").add({
        name: this.name,
        email: this.email,
        message: this.textArea
      }).then(() => {
        this.snackbar.text = "Mensagem enviada com sucesso"
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = "Erro ao enviar mensagem"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })*/
    }
  }
};
</script>
